import img_1 from '../image/Portfolio/img_1.webp'
import img_2 from '../image/Portfolio/img_2.webp'
import img_3 from '../image/Portfolio/img_3.webp'
import img_4 from '../image/Portfolio/img_4.webp'
import img_5 from '../image/Portfolio/img_5.webp'
import img_6 from '../image/Portfolio/img_6.webp'

export const arrPortfolio = [{image: img_1, title: 'Cчетчик'}, {image: img_2, title: 'Cчетчик'}, {image: img_3, title: 'Cчетчик'}, {image: img_4, title: 'Cчетчик'}, {image: img_5, title: 'Cчетчик'}, {image: img_6, title: 'Cчетчик'},];

export const arrAdvantages = [{text:'Доступные ЦЕНЫ'}, {text:'Гарантия КАЧЕСТВА'}, {text:'Многолетний ОПЫТ'}, {text:'Персональный ПОДХОД'}, {text:'Полный пакет ДОКУМЕНТОВ'}, {text:'Работаем без ВЫХОДНЫХ'},];

