import React, {useCallback, useState} from 'react'
import { Route, Routes, useLocation} from 'react-router-dom'
import app from './App.module.scss'
import {Main} from '../../pages/Main/Main'
import { Header } from '../Header/Header'
import { Footer } from '../Footer/Footer'
import { MenuMobile } from '../MenuMobile/MenuMobile'
import { ModalForm } from '../ModalForm/ModalForm'
import { ButtonCommunication } from '../ButtonCommunication/ButtonCommunication'
import { Modal } from '../Modal/Modal'
import { ModalImage } from '../ModalImage/ModalImage'
import { Products } from '../../pages/Products/Products'
import { HeaderProducts } from '../HeaderProducts/HeaderProducts'
import { MenuMobileProducts } from '../MenuMobileProducts/MenuMobileProducts'

function App() {

  const routes  = useLocation()

  const [isMenuMobile, setIsMenuMobile] = useState(false)
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPopupImageOpen, setIsPopupImageOpen] = useState(false)
  const [isIndex, setIsIndex] = useState(null)
  const [isArr, setIsArr] = useState(null)

  const vh = window.innerHeight / 100;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  const handleCloseMenuMobile = useCallback(() => {
    setIsMenuMobile(false)
  }, []);

  const handleOpenMenuMobile = useCallback(() => {
    setIsMenuMobile(true)
  }, []);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);

  const handleImageClose = useCallback(() => {
    setIsPopupImageOpen(false)
  }, []);

  const handleImageOpen = useCallback((index, arr) => {
      setIsIndex(index)
      setIsArr(arr)
      setIsPopupImageOpen(true)
  }, [])


  return (
    <div className={app.page}>
      {routes.pathname === '/' ? (<Header
        isOpen={handleOpenMenuMobile}
      />) : (<HeaderProducts isOpen={handleOpenMenuMobile}/>)}
      <Routes>
      <Route  path='/'  element={
        <Main
        isOpen={handleFormOpen}
        popupOpen={handleOpen}
        isImageOpen={handleImageOpen}
        />
        }/>
      <Route  path='/produkciya'  element={
        <Products
        />
        }/>  
      </Routes>
      <Footer/>
      <ButtonCommunication
          isOpen={handleFormOpen}
      />
      {isMenuMobile && <MenuMobile
        onClose={handleCloseMenuMobile}
      />}
      {isMenuMobile & routes.pathname === '/produkciya' && 
      <MenuMobileProducts
        onClose={handleCloseMenuMobile}
      />}
      {isPopupFormOpen && 
      <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isPopupOpen && 
      <Modal
        onClose={handleClose}
      />}
      {isPopupImageOpen && 
      <ModalImage
        onClose={handleImageClose}
        index={isIndex}
        arr={isArr}
      />}
    
    </div>
  );
}

export default App;
