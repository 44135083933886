import React from 'react'
import styles from './ListProducts.module.scss'
import { motion } from "framer-motion"
import icon_1 from '../../image/ListProducts/img_1.webp'
import icon_2 from '../../image/ListProducts/img_2.webp'
import icon_3 from '../../image/ListProducts/img_3.webp'
import icon_4 from '../../image/ListProducts/img_4.webp'
import icon_5 from '../../image/ListProducts/img_5.webp'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const ListProducts = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Продукция которую <span>мы предлагаем</span></motion.h2>
                    <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_1} alt='трубы'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Труба г/д 219х8х4000-12000 мм 09Г2С ГОСТ 8732-78</h3>
                                <p className={styles.item_text}>Наличие - <span>250 тонн</span></p>
                                <p className={styles.item_text}>Цена - <span>132 т.р. С НДС</span></p>
                                <a className={styles.button}  target='_blank' rel='noopener noreferrer' href='https://wa.me/+79688101111' aria-label='связаться вацап'>заказать трубы</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_2} alt='трубы'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Труба г/д 325х8х4000-12000 мм 09Г2С ГОСТ 8732-78</h3>
                                <p className={styles.item_text}>Наличие - <span>115 тонн</span></p>
                                <p className={styles.item_text}>Цена - <span>132 т.р. С НДС</span></p>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79688101111' aria-label='связаться вацап'>заказать трубы</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_3} alt='трубы'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Труба г/д 426х10х4000-12000 мм 09Г2С ГОСТ 8732-78</h3>
                                <p className={styles.item_text}>Наличие - <span>167 тонн</span></p>
                                <p className={styles.item_text}>Цена - <span>132 т.р. С НДС</span></p>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79688101111' aria-label='связаться вацап'>заказать трубы</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_4} alt='трубы'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Труба 530х7х10500 мм 17Г1С-У ГОСТ 20295-85</h3>
                                <p className={styles.item_text}>Наличие - <span>350 тонн</span></p>
                                <p className={styles.item_text}>Цена - <span>115 т.р. С НДС</span></p>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79688101111' aria-label='связаться вацап'>заказать трубы</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_5} alt='трубы'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Труба 1020х10х10500 мм 17Г1С-у ГОСТ 20295-85</h3>
                                <p className={styles.item_text}>Наличие - <span>200 тонн</span></p>
                                <p className={styles.item_text}>Цена - <span>111 т.р. С НДС</span></p>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79688101111' aria-label='связаться вацап'>заказать трубы</a>
                            </div>
                        </li>
                    </motion.ul>
                </div>
        </motion.section>
        
    )
}