import React, {useEffect} from 'react'
import styles from './Products.module.scss'
import { ListProducts } from '../../components/ListProducts/ListProducts'
import BreadCrumbs  from '../../components/BreadCrumbs/BreadCrumbs'

export const Products = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className={styles.main}>
            <BreadCrumbs
                text={'Продукция'}
            />
            <ListProducts/>
        </section>
    )
}