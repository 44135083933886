import React from 'react'
import styles from './Services.module.scss'
import { motion } from "framer-motion"
import icon_1 from '../../image/Services/img_2.webp'
import icon_2 from '../../image/Services/img_3.webp'
import icon_3 from '../../image/Services/img_4.webp'
import icon_4 from '../../image/Services/img_5.webp'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const Services = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Услуги которые <span>мы предлагаем</span></motion.h2>
                    <motion.ul className={styles.list} variants={boxAnimation}>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_1} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Установка и замена счетчиков&#160;воды</h3>
                                <p className={styles.item_text}>Установка - <span>от 3500 &#8381;</span></p>
                                <p className={styles.item_text}>Замена - <span>от 2500 &#8381;</span></p>
                                <a className={styles.button}  target='_blank' rel='noopener noreferrer' href='https://wa.me/+79254187755' aria-label='связаться вацап'>заказать услугу</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_2} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Установка и замена счетчиков&#160;тепла</h3>
                                <p className={styles.item_text}>Установка - <span>от 7900 &#8381;</span></p>
                                <p className={styles.item_text}>Замена - <span>от 5500 &#8381;</span></p>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79254187755' aria-label='связаться вацап'>заказать услугу</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_3} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Метрологическая поверка счетчиков</h3>
                                <p className={styles.item_text}>Счетчиков воды - <span>от 500 &#8381;</span></p>
                                <p className={styles.item_text}>Счетчиков тепла - <span>от 3000 &#8381;</span></p>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79254187755' aria-label='связаться вацап'>заказать услугу</a>
                            </div>
                        </li>
                        <li className={styles.item}>
                        <img className={styles.item_image}  src={icon_4} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Опломбировка счетчиков</h3>
                                <p className={styles.item_text}>Счетчиков воды - <span>от 600 &#8381;</span></p>
                                <p className={styles.item_text}>Счетчиков тепла - <span>от 600 &#8381;</span></p>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79254187755' aria-label='связаться вацап'>заказать услугу</a>
                            </div>
                        </li>
                    </motion.ul>
                </div>
        </motion.section>
        
    )
}