import React from 'react'
import styles from './InitialPage.module.scss'
import { motion } from "framer-motion"
import disactive from '../../image/InitialPage/check.svg'

const titleAnimation = {
    hidden: {
        x: -800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: 800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}


export const InitialPage = ({isOpen}) => {
    return (
        <motion.section 
        className={styles.main} 
        id='initial'
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}} 
        >
                <div className={styles.item}>
                    <div className={styles.overlay}>
                        <div className={styles.container}>
                            <motion.h1 className={styles.title} variants={titleAnimation}>Установка и замена счётчиков воды и&#160;тепловой энергии</motion.h1>
                            <motion.div className={styles.box} variants={subtitleAnimation}>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                        <div className={styles.box_text}>
                                            <p className={styles.text}>Работы любой сложности</p>
                                        </div>
                                </div>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                        <div className={styles.box_text}>
                                            <p className={styles.text}>Жилые и нежилые помещения</p>
                                        </div>
                                </div>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Полный пакет документов</p>
                                    </div>
                                </div>
                                <div className={styles.box_item}>
                                    <div className={styles.icon_boxs}>
                                        <div className={styles.icon_bg}>
                                            <span className={styles.pulse}></span>
                                            <img className={styles.icon} src={disactive} alt='галочка'/>
                                        </div>
                                    </div>
                                    <div className={styles.box_text}>
                                        <p className={styles.text}>Устраняем любые протечки</p>
                                    </div>
                                </div>
                            </motion.div>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79636877867' aria-label='связаться вацап'>вызвать мастера</a>
                            <a className={styles.phone}  href="tel:+79636877867" target='_blank' rel='noopener noreferrer'>вызвать мастера</a>
                        </div>
                    </div>
                </div>  
        </motion.section>
    )
}