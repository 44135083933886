import React from 'react'
import { Link } from 'react-router-dom'
import styles from './MenuMobileProducts.module.scss'
import logo from '../../image/Header/logo.png'

export const MenuMobileProducts = ({onClose}) => {

    return (
        <div className={styles.main}>
            <div className={styles.box}>
                <img className={styles.logo} src={logo} alt = 'логотип'/>
                <button className={styles.button} onClick={onClose} aria-label='закрыть меню'/>
            </div>
            <nav className={styles.nav}>
                    <Link className={styles.link} to='/' onClick={onClose}>Наши услуги</Link>
                    <Link className={styles.link} to="/produkciya" onClick={onClose}>Продукция</Link>
            </nav>
        </div>
    );
}